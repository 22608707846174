import { useMemo, useRef } from 'react'

import { DeviceType } from 'services/partition/types'

import { ReactComponent as Share } from 'assets/svg/share.svg'

import { generateDeviceCode, getDeviceInfo } from 'utilities/devices'

import styles from './EquipmentCard.module.scss'
import { Icon, IconWithTooltip, Text, Tooltip } from 'components'
import { useToggle } from 'shared/hooks'

import { EquipmentCardAttributes } from 'domains/customer/screens/Equipments/types'
import {
  getCommunicationLevelIcon,
  getEquipmentBatteryIcon,
} from 'domains/customer/screens/Equipments/utilities/icons'

import { dateNow } from 'utilities/date'
import { ReactComponent as TamperOpenIcon } from '../../assets/exclamation.svg'
import { ReactComponent as AnnuledIcon } from '../../assets/annulled.svg'
import { formatHiddenZoneToForTooltip } from '../../utilities/time/time'

type EquipmentCardProps = {
  equipment: EquipmentCardAttributes
  onPress: (equipment: EquipmentCardAttributes) => void
  isSelected?: boolean
}

export const EquipmentCard = ({
  equipment,
  onPress,
  isSelected,
}: EquipmentCardProps) => {
  const { deviceTypeCode } = equipment

  const equipmentInfo = useMemo(() => {
    return getDeviceInfo(deviceTypeCode)
  }, [deviceTypeCode])

  const tooltip = useToggle()
  const sharedDeviceRef = useRef<HTMLDivElement>(null)

  const title = equipment.contact?.name || equipment.name

  const formattedCode =
    equipment.isUbisafe && equipment
      ? generateDeviceCode(equipment.code || '', equipment.deviceTypeCode)
      : equipment.code || ''

  return (
    <li
      className={[
        styles.equipment,
        isSelected && styles.selected,
        equipment.tamper && styles.error,
      ].join(' ')}
      aria-label="equipment-card"
      onClick={() => onPress(equipment)}
    >
      {equipment.isUbisafe ? (
        equipmentInfo?.image
      ) : (
        <div className={styles.emptyStateRender}>
          <Icon
            name="photo-off"
            width={56}
            height={56}
            color="accent-gray-medium"
          />
        </div>
      )}

      <div className={styles.equipmentInfo}>
        <p>
          {equipment.isUbisafe ? title : `${equipment.code ?? ''} - ${title}`}
        </p>
        <small>
          {equipmentInfo?.type} {equipment.manufacturer?.name}
        </small>
        <Text weight="light" size="sm">
          {formattedCode}
        </Text>
        {equipment.deviceTypeCode !== DeviceType.RemoteControl && (
          <div className={styles.iconsWrapper}>
            {equipment.isUbisafe && (
              <>
                <div className={styles.wifiIcon}>
                  {getCommunicationLevelIcon(
                    equipment?.deviceCommunicationLevel || 0,
                    equipment?.deviceCommunicationStatus,
                  )}
                </div>
                {getEquipmentBatteryIcon(equipment?.batteryAlarmed || false)}
                {equipment?.tamper && (
                  <IconWithTooltip Icon={TamperOpenIcon} text="Tamper aberto" />
                )}
                {equipment.partitions && equipment.partitions?.length > 1 && (
                  <div
                    ref={sharedDeviceRef}
                    onMouseEnter={tooltip.show}
                    onMouseLeave={tooltip.hide}
                  >
                    <Share aria-label="shared-device" />
                    <Tooltip
                      parentRef={sharedDeviceRef}
                      type="informative"
                      isVisible={tooltip.isVisible}
                    >
                      {equipment?.partitions?.map((partition) => {
                        return <p key={partition.id}>{partition.name};</p>
                      })}
                    </Tooltip>
                  </div>
                )}
              </>
            )}
            {equipment.hiddenZone || equipment.hiddenZoneTo ? (
              <IconWithTooltip
                Icon={AnnuledIcon}
                text={
                  equipment.hiddenZoneTo
                    ? `Equipamento anulado até ${formatHiddenZoneToForTooltip(
                        equipment.hiddenZoneTo,
                      )}`
                    : 'Equipamento anulado até o próximo desarme.'
                }
              />
            ) : null}
          </div>
        )}
      </div>
      {!equipment.deviceCommunicationStatus && equipment.isUbisafe && (
        <div className={styles.cardOverlay}>
          O dispositivo não está se comunicando desde <br />
          {dateNow(equipment.lastConnectionTimestamp)}
        </div>
      )}
    </li>
  )
}
